<template>
  <div class="shilut-db">
    <div class="shilut-db-header">
        <h1>בחירת שלטים</h1>
    </div>
    <div class="shilut-db-tools">
        <el-select style="margin-right:5px;" v-model="selected_department" class="m-2" placeholder="מחלקה" size="large">
            <el-option
            v-for="department in departments"
            :key="department.uid"
            :value="department.name"
            />
        </el-select>
        <el-input  style="width:30%; margin-right:5px;" v-model="search" placeholder="חיפוש" />
        <el-button style="margin-right:5px;" @click="handle_reload_signs" type="success">טען שלטים</el-button>
        <div @click="$emit('back')" class="back">
            <i class="material-icons">arrow_back</i>
        </div>
    </div>
    <div class="shilut-db-content">
        <table>
            <tr>
                <th style="cursor:pointer;" @click="handleSortTable('ברקוד', !selectedSorted.order)">ברקוד</th>
                <th style="cursor:pointer;" @click="handleSortTable('מחלקה', !selectedSorted.order)">מחלקה</th>
                <th style="cursor:pointer;" @click="handleSortTable('עברית', !selectedSorted.order)">שם פריט עברית</th>
                <th style="cursor:pointer;" @click="handleSortTable('לועזי', !selectedSorted.order)">שם פריט לועזי</th>
                <th style="cursor:pointer;" @click="handleSortTable('מחיר', !selectedSorted.order)">מחיר</th>
                <th>יחידת מידה</th>
                <th>פעולות</th>
            </tr>
            <template v-for="item in sorted_list" :key="item.barcode">
                <tr>
                   <td>{{item.barcode}}</td> 
                   <td>{{item.department}}</td> 
                   <td>{{item.he_name}}</td> 
                   <td>{{item.ru_name}}</td> 
                   <td>{{item.price}} &#8362;</td> 
                   <td>{{item.size}} <span v-if="item.size=='יח'">'</span></td>
                   <td>
                     <Checkbox v-model="selectes_signs" :value="item" />
                   </td> 
                </tr>
            </template>   
        </table>
    </div>
    <div class="shilut-db-pagination">
        <el-pagination 
             v-model:currentPage="pagination.current_page"
             background 
            :page-size="pagination.number_per_page"
            layout="prev,pager,next"
            :total="filter_by_search.length" 
        />
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_all_signs_from_db,get_all_departments} from '../../Scripts/scripts'
import { computed} from '@vue/runtime-core'
import Checkbox from 'primevue/checkbox';


export default {
    props:['selected_items_before'],
    emits:['back','reload_signs'],
    components:{Checkbox},

    setup(props,{emit}){

        const selectes_signs = ref([])

        const departments = ref([])
        const selected_department = ref('')
      
        const search = ref('')

        const items = ref([])
      
        const pagination = ref({
            current_page:1,
            number_per_page:40,
        })

        const handle_reload_signs = () => {
            if(selectes_signs.value.length > 0){
                emit('reload_signs',selectes_signs.value)
            }
        }   
     
        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const slice_list = ref(computed(()=>{
            return filter_by_search.value.slice((pagination.value.current_page-1)*pagination.value.number_per_page,
            ((pagination.value.current_page-1)*pagination.value.number_per_page)+pagination.value.number_per_page)
        }))

        const sorted_list = ref(computed(() => {
            if (selectedSorted.value.name == "")return slice_list.value;
            if (selectedSorted.value.name == "ברקוד") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.barcode > b.barcode
                        ? 1
                        : b.barcode  > a.barcode
                        ? -1
                        : 0;
                    }
                    return a.barcode > b.barcode
                    ? -1
                    : b.barcode  > a.barcode
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחלקה") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.department > b.department
                        ? 1
                        : b.department  > a.department
                        ? -1
                        : 0;
                    }
                    return a.department > b.department
                    ? -1
                    : b.department  > a.department
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "עברית") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.he_name > b.he_name
                        ? 1
                        : b.he_name  > a.he_name
                        ? -1
                        : 0;
                    }
                    return a.he_name > b.he_name
                    ? -1
                    : b.he_name  > a.he_name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "לועזי") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.ru_name > b.ru_name
                        ? 1
                        : b.ru_name  > a.ru_name
                        ? -1
                        : 0;
                    }
                    return a.ru_name > b.ru_name
                    ? -1
                    : b.ru_name  > a.ru_name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחיר") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.price > b.price
                        ? 1
                        : b.price  > a.price
                        ? -1
                        : 0;
                    }
                    return a.price > b.price
                    ? -1
                    : b.price  > a.price
                    ? 1
                    : 0;
                });
            }
        }));
        
        const filter_by_search = ref(computed(() => {
            if(!search.value) return filter_by_department.value
            return filter_by_department.value.filter(item=>{
                if(item.barcode.includes(search.value)) return item
                if(item.he_name.includes(search.value)) return item
                if(item.ru_name.includes(search.value)) return item
                if(item.price == search.value) return item
            })
        }))

        const filter_by_department = ref(computed(()=>{
            if(selected_department.value== '' || selected_department.value == 'הכל')
            return items.value
            return items.value.filter(item=>item.department == selected_department.value)
        }))
        
        const init = async()=>{
           items.value = await get_all_signs_from_db()
           departments.value = [{name:'הכל',uid:'asdfasg3276478326'},...await get_all_departments()]

           if(props.selected_items_before && props.selected_items_before.length>0){
            selectes_signs.value = props.selected_items_before
           }
        }

        init()
        return{
            handleSortTable,
            handle_reload_signs,
            selectes_signs,
            search,
            items,
            pagination,
            filter_by_search,
            selectedSorted,
            sorted_list,
            slice_list,
            departments,
            selected_department,
        }
    }
}
</script>

<style scoped>
    .shilut-db{
        margin: 0 auto;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        padding: 5px;
        color: #fff;
    }
    .shilut-db-header{
        width: 100%;
        height: 50px;
        background: var(--secondary);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .shilut-db-tools{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .back{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--danger);
        color: #fff;
        cursor: pointer;
        margin-right: auto;
    }
    .shilut-db-content{
        width: 100%;
        height: calc(100% - 150px);
        overflow: hidden;
        overflow-y: auto;
    }
    .shilut-db-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }
    .icon{
        cursor: pointer;
    }
    .delete-icon{
        color: var(--danger);
        margin: 0 5px;
    }
    .edit-icon{
        color: var(--warning);
    }

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--success);
        color: #fff;
        
    }
</style>